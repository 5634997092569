import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-label-icon',
  templateUrl: './label-icon.component.html',
  styleUrls: ['./label-icon.component.scss']
})
export class LabelIconComponent implements OnInit {

  @Input() label: string = '';
  @Input() icon: string = '';
  @Input() before: boolean = true;
  constructor() { }

  ngOnInit() {
  }

}
